export default defineNuxtRouteMiddleware(async () => {
	const userStore = useUserStore()
	const routeUrl = useRouteUrl()

	const profile = userStore.profile

	if (profile?.type != "admin") {
		console.log("admin-auth-redirect")
		return navigateTo(routeUrl.AdminLoginUrl())
	}
})
